<template>
  <!-- :visible="true" -->
  <b-sidebar
    id="MarkAttendanceSidebar"
    ref="MarkAttendanceSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @shown="resetFormData"
    @change="(val) => $emit('update:sidebarMarkAttendanceActive', val)"
    width="60%"
    title="Scrollable Content"
    no-close-on-backdrop
  >
    <template #default="{ hide }">
      <b-link class="text-primary floating-close" @click="confirmCloseButton">
        <feather-icon icon="XCircleIcon" size="26" class="mr-25" />
      </b-link>
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 border-bottom sticky-top"
      >
        <h3 class="mb-0 font-weight-bold">Attendance</h3>
        <div>
          <b-button
            v-if="false"
            variant="outline-primary"
            class="mr-2 min-w-100 px-3"
            @click="hide"
            pill
          >
            Cancel
          </b-button>
          <b-button
            pill
            variant="primary"
            @click="formSubmitted()"
            class="px-3"
          >
            Submit
          </b-button>
        </div>
      </div>

      <!-- BODY -->
      <validation-observer ref="accountRules" tag="form">
        <b-form autocomplete="off" id="attendance-form" class="p-2">
          <b-row>
            <b-col md="6">
              <b-form-group>
                <label class="required-l" for="date">Attendance Date</label>
                <flat-pickr
                  id="date"
                  v-model="data_local.date"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altFormat: 'Y-m-d',
                    altInput: true,
                  }"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <div>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="check_in">Check In</label>
                  <flat-pickr
                    id="check_in"
                    class="form-control"
                    v-model="data_local.check_in"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      altFormat: 'h:i K',
                      altInput: true,
                    }"
                  />
                </b-form-group>
              </b-col>

              <b-col md="6">
                <b-form-group>
                  <label class="required-l" for="check_out">Check Out</label>
                  <flat-pickr
                    id="check_out"
                    v-model="data_local.check_out"
                    class="form-control"
                    :config="{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'H:i',
                      altFormat: 'h:i K',
                      altInput: true,
                    }"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        </b-form>
      </validation-observer>
      <b-overlay :show="isBusy" no-wrap> </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  VBTooltip,
  BFormTextarea,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onMounted, ref, watch } from "@vue/composition-api";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import VueTimepicker from "vue2-timepicker/src";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";

import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import { bus } from "@/main.js";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,

    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    flatPickr,
    VueTimepicker,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  model: {
    prop: "sidebarMarkAttendanceActive",
    event: "update:sidebarMarkAttendanceActive",
  },
  props: {
    date: {
      type: String,
      required: false,
      default: "",
    },
    userHashId: {
      type: String,
      required: false,
      default: "",
    },
    sidebarMarkAttendanceActive: {
      type: Boolean,
      required: true,
      default: true,
    },
    itemData: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      isBusy: false,
      quarterHours: ["00", "30"],
      times: [],
      newUserData: this.$cookies.get("userData"),
      data_local: {
        date: this.$props.date,
        check_in: "09:00",
        check_out: "18:30",
      },
      defaultForm: {
        date: this.$props.date,
        check_in: "09:00",
        check_out: "18:30",
      },
      employeeListOptions: [],
    };
  },
  computed: {
    hasChanged() {
      return Object.keys(this.data_local).some(
        (field) => this.data_local[field] !== this.defaultForm[field]
      );
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-posts";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      // store.registerModule(USER_APP_STORE_MODULE_NAME, postStoreModule);

      // UnRegister on leave
      onUnmounted(() => {
        if (store.hasModule(USER_APP_STORE_MODULE_NAME))
          store.unregisterModule(USER_APP_STORE_MODULE_NAME);
      });

    const { refFormObserver, getValidationState, resetForm } = formValidation();

    return {
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
  watch: {
    sidebarMarkAttendanceActive(val) {
      this.data_local = {
        date: this.$props.date,
        check_in: "09:00",
        check_out: "18:30",
      };
    },
  },
  created() {
    for (var i = 0; i < 24; i++) {
      for (var j = 0; j < 2; j++) {
        if (i < 10) {
          this.times.push("0" + i + ":" + this.quarterHours[j]);
        } else {
          this.times.push(i + ":" + this.quarterHours[j]);
        }
      }
    }
  },
  mounted() {
    this.fetchEmployeeList();
  },
  methods: {
    confirmCloseButton() {
      if (this.hasChanged) {
        this.$swal({
          title: "Are you sure?",
          text: "You are about to exit this form , if you proceed the form data will be lost.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.resetFormData();
            this.$refs.MarkAttendanceSidebar.hide();
          }
        });
      }
      else
      {
        this.resetFormData();
        this.$refs.MarkAttendanceSidebar.hide();
      }
    },
    fetchEmployeeList() {
      const self = this;
      let data = {};
      data.url = "list/user-list-for-tag-names";
      this.$store
        .dispatch("app/get", data)
        .then((res) => {
          this.employeeListOptions = res.data.data;
        })
        .catch((err) => {
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "fetch Employee List failed.",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: err.toString(),
          //   },
          // });

          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'fetch Employee List failed.',
                icon: 'BellIcon',
                variant: 'danger',
                text: err.toString(),
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    resetFormData() {
      console.log("reset");
      this.$refs.accountRules.reset();

      // this.data_local = {
      //   priority: "medium",
      //   status: "open",
      //   get_customfields_data: [],
      //   files: [],
      // };
      // if (this.itemId) {
      //   this.data_local = this.itemData;
      // }
    },
    openWindow(link) {
      window.open(link);
    },
    formSubmitted() {
      const self = this;
      const formData = new FormData(document.getElementById("attendance-form"));

      formData.append("check_in", self.data_local.check_in);
      formData.append("check_out", self.data_local.check_out);
      formData.append("date", self.data_local.date);

      let data = {};
      data.url = "add-attendance-by-user/" + self.userHashId;
      data.params = formData;
      this.isBusy = true;
      this.$store
        .dispatch("app/store", data)
        .then((res) => {
          this.isBusy = false;
          bus.$emit("fetchUserData");
          self.$refs.MarkAttendanceSidebar.hide();
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Attendance Marked",
          //     icon: "BellIcon",
          //     variant: "success",
          //     text: res.data.message,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: "Attendance Marked",
                icon: 'BellIcon',
                variant: 'success',
                text: res.data.message,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 successful-info",
              hideProgressBar : true,
            });
        })
        .catch((error) => {
          this.isBusy = false;
          bus.$emit("fetchUserData");
          // this.$toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Oops! Attendance Marking Failed",
          //     icon: "BellIcon",
          //     variant: "danger",
          //     text: error,
          //   },
          // });
          this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Oops! Attendance Marking Failed',
                icon: 'BellIcon',
                variant: 'danger',
                text: error,
              },
            }, {
              timeout: 2000, 
              position: 'bottom-center',
              toastClassName:"radius-8 error-info ",
              hideProgressBar : true,
            });
        });
    },
    validationForm() {
      const self = this;

      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.vue__time-picker {
  width: 6em !important;
}
.vue__time-picker input.display-time {
  width: 100% !important;
  background: whitesmoke;
  height: 38px;
  border-radius: 5px;
  margin-left: 3px;
  color: grey;
}
.vue__time-picker .dropdown,
.vue__time-picker-dropdown {
  height: 14rem !important;
}
.vue__time-picker .dropdown .select-list,
.vue__time-picker-dropdown .select-list {
  height: 14rem !important;
}
select#year,
select#month,
select#day {
  padding: 5px 10px;
  background: #eaeaea;
  border: solid rgb(218, 218, 218) 0.5px;
  border-radius: 5px;
  margin-left: 5px;
}
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.filepond--credits {
  display: none;
}
.flatpickr-input {
  background: whitesmoke !important;
}
</style>
