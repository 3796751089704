import axios from "@axios";

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAttendanceMusters(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/attendance-muster-list", { params: queryParams })
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
        fetchAttendanceMuster(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`attendance-muster-list/${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error));
            });
        },
        getCustomFields() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/attendance-muster-list/create")
                    .then(response => {
                        resolve(response);
                    })
                    .catch(error => reject(error));
            });
        },
    }
};
